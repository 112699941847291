// src/pages/UploadImage.js

import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import axios from 'axios';

export default function UploadImage() {
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cooldownTimer, setCooldownTimer] = useState(0);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [settings, setSettings] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    document.title = 'MazenDev Image Uploader';
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/settings');
        if (response.status === 200) {
          setSettings(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        setSettings({
          pageTitle: 'Default Title',
          pageSubtitle: 'Default subtitle for fallback.',
          backgroundColor: '#ffffff',
          buttonColor: '#0000ff',
          buttonText: 'Upload Photo',
          heroSectionColor: '#cccccc',
          logoUrl: '',
        }); // Provide fallback settings if fetch fails
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchSettings();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const getCroppedImage = async () => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          const croppedFile = new File([blob], file.name, { type: file.type });
          resolve(croppedFile);
        }, file.type);
      };
    });
  };

  const handleUpload = async () => {
    if (!file || !croppedAreaPixels) {
      setUploadStatus('Please select a file and crop it.');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const croppedFile = await getCroppedImage();

      const formData = new FormData();
      formData.append('photo', croppedFile);

      await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });

      setUploadStatus('Upload successful!');
      setCooldownTimer(60);
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('Error uploading photo.');
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (cooldownTimer > 0) {
      timer = setInterval(() => {
        setCooldownTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldownTimer]);

  // Show loading spinner while fetching settings
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <p>Loading settings...</p>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="home-container" style={{ backgroundColor: settings.backgroundColor }}>
      <div className="container">
        <div
          className="hero-section"
          style={{ background: settings.heroSectionColor }}
        >
          {settings.logoUrl && <img src={settings.logoUrl} alt="Logo" className="logo" />}
          <h1 className="hero-title">{settings.pageTitle}</h1>
          <p className="hero-subtitle">{settings.pageSubtitle}</p>
        </div>

        <div className="upload-section">
          <input type="file" accept="image/*" onChange={handleFileChange} className="form-control mb-3" />

          {imageSrc && (
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1} // Keep a square crop aspect={1} → Square (Width:Height = 1:1) || aspect={16 / 9} → Widescreen (Width:Height = 16:9)
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
          )}

          {isUploading && (
            <div className="progress-container mt-3">
              <div
                className="progress-bar progress-bar-custom-pink"
                role="progressbar"
                style={{ width: `${uploadProgress}%`, height: '25px' }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {uploadProgress}%
              </div>
            </div>
          )}

          <button
            className="btn btn-custom-pink mt-3"
            onClick={handleUpload}
            disabled={isUploading || cooldownTimer > 0}
            style={{ backgroundColor: settings.buttonColor }}
          >
            {isUploading ? 'Uploading...' : cooldownTimer > 0 ? `Please wait ${cooldownTimer}s` : settings.buttonText}
          </button>
          {uploadStatus && <p className="upload-status mt-3">{uploadStatus}</p>}
        </div>
      </div>

      <style jsx>{`
          .crop-container {
          position: relative;
          width: 100%;
          height: 300px;
          margin: 20px 0;
        }
        .home-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${settings.backgroundColor};
          font-family: 'Arial', sans-serif;
          color: #333;
          padding: 20px;
        }

        .logo {
          max-width: 150px;
          margin-bottom: 20px;
        }

        .container {
          max-width: 800px;
          margin: auto;
          padding: 20px;
          text-align: center;
          background: white;
          border-radius: 15px;
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
          transition: box-shadow 0.3s ease;
        }

        .container:hover {
          box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
        }

        .hero-section {
          background: ${settings.heroSectionColor};
          padding: 40px;
          border-radius: 15px;
          color: white;
          margin-bottom: 30px;
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
        }

        .hero-title {
          font-size: 2.5rem;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .hero-subtitle {
          font-size: 1.3rem;
        }

        .upload-section {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .btn-custom-pink {
          border: none;
          color: white;
          font-size: 1.1rem;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          text-align: center;
          border-radius: 5px;
        }

        .btn-custom-pink:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 15px rgba(214, 51, 132, 0.4);
        }

        .thumbnail {
          max-width: 60%;
          height: auto;
          margin: 20px auto;
          border-radius: 10px;
          border: 2px solid #d63384;
          display: block;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }

        .thumbnail:hover {
          transform: scale(1.05);
          box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
        }

        .progress-container {
          width: 100%;
          background-color: #e9ecef;
          border-radius: 5px;
          overflow: hidden;
        }

        .progress-bar-custom-pink {
          background-color: #d63384;
          font-weight: bold;
          color: white;
          line-height: 25px;
        }

        .upload-status {
          font-size: 16px;
          color: #007bff;
        }
      `}</style>
    </div>
  );
}
