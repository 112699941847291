// src/App.js
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import UploadImage from './pages/UploadImage';
import Gallery from './pages/Gallery';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard'; // Import the AdminDashboard component
import AdminSettings from './pages/AdminSettings'; // Import the new settings component
import UnderConstruction from './pages/UnderConstruction'
import ApiDoc from './pages/ApiDoc'
import AboutMe from './pages/AboutMe'

// Define routes for the application
const router = createBrowserRouter(
  [
    {
      path: "/", // Under Construction
      element: <UnderConstruction />,
    },
    {
      path: "/up", // User upload or take a photo
      element: <UploadImage />,
    },
    {
      path: "/login-me", // Admin login page
      element: <Login />,
    },
    {
      path: "/gallery", // Admin Gallery after successful login
      element: <Gallery />,
    },
    {
      path: "/admin", // Admin Dashboard page
      element: <AdminDashboard />,
    },
    {
      path: "/admin/settings", // Admin settings page
      element: <AdminSettings />, // Add the route for the settings page
    },
    {
      path: "/api-doc", // Admin settings page
      element: <ApiDoc />, // Add the route for the settings page
    },
    {
      path: "/about", // Admin settings page
      element: <AboutMe />, // Add the route for the settings page
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionStatusRevalidation: true,
    },
  }
);

// Main App component
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
