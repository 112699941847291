import React from 'react';

const AboutMe = () => {
    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                    <div className="card shadow-sm border-0">
                        <div className="card-body p-4 text-center">
                            <img 
                                src="/mazen.jpg" 
                                alt="Mazen" 
                                className="img-fluid rounded-circle mb-3" 
                                style={{ 
                                    width: '100%', 
                                    maxWidth: '150px', 
                                    height: 'auto', 
                                    objectFit: 'cover' 
                                }} 
                            />
                            <h2 className="card-title mb-3">About Me</h2>
                            <p className="card-text">
                                Hello! I'm Mazen, a Game Development Manager and tech enthusiast. I specialize in Unreal Engine, AI, and interactive photo applications.
                            </p>
                            <p className="card-text">
                                I enjoy working on projects that combine creativity and technology, aiming to create engaging and interactive experiences for users.
                            </p>
                            <p>If you'd like to get in touch, feel free to contact me via WhatsApp.</p>
                            
                            <a 
                                href="https://wa.me/201222500149?text=Hello%20Mazen,%20I%20visited%20your%20website!" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="btn btn-success btn-lg mt-3"
                            >
                                <i className="bi bi-whatsapp me-2"></i> WhatsApp Me
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
