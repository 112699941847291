// src/pages/AdminSettings.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function AdminSettings() {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    pageTitle: '',
    pageSubtitle: '',
    backgroundColor: '#ffffff',
    buttonColor: '#007bff',
    buttonText: '',
    logoUrl: '',
    heroSectionColor: '#007bff',
  });

  const [message, setMessage] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    document.title = 'Settings';
  }, []);

  // Fetch current settings from backend
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/settings');
        if (response.status === 200) {
          setSettings(response.data);
          setLogoPreview(response.data.logoUrl);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    };
    fetchSettings();
  }, []);

  // Authentication function moved to useCallback to ensure proper dependency handling
  const authenticateUser = useCallback(async () => {
    try {
      const response = await axios.get('/api/user/profile');
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      console.error('User not authenticated:', error);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    // Check if user is authenticated, otherwise redirect to login
    authenticateUser();
  }, [authenticateUser]);

  // Handle logout
  const handleLogout = async () => {
    try {
      await axios.get('/api/logout');
      navigate('/'); // Redirect to home page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  // Handle logo file change
  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    setLogoPreview(URL.createObjectURL(file));
  };

  // Handle logo upload separately
  const handleUploadLogo = async () => {
    if (!logoFile) {
      setMessage('Please select a logo to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('logo', logoFile);

    try {
      const response = await axios.post('/api/upload/logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          logoUrl: response.data.logoPath,
        }));
        setMessage('Logo uploaded successfully!');
      }
    } catch (error) {
      console.error('Failed to upload logo:', error);
      setMessage('Failed to upload logo. Please try again.');
    }
  };

  // Handle settings update submission
  const handleSaveSettings = async (e) => {
    e.preventDefault();

    // Save the settings
    try {
      const response = await axios.post('/api/settings', settings);
      if (response.status === 200) {
        setMessage('Settings updated successfully!');
      }
    } catch (error) {
      console.error('Failed to update settings:', error);
      setMessage('Failed to update settings. Please try again.');
    }
  };

  return (
    <div className="admin-settings-container" style={{ backgroundColor: settings.backgroundColor, padding: '30px', minHeight: '100vh' }}>
      {/* Navigation Bar */}
      <nav className="navbar navbar-light bg-light shadow-sm mb-4">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <button className="btn btn-danger logout-button" onClick={handleLogout}>
            Logout
          </button>
          <div className="d-flex align-items-center ms-auto">
            <span className="fw-bold me-2">{user?.name || 'Admin'}</span>
            {user?.picture && (
              <img
                src={user.picture}
                alt="Profile"
                className="profile-picture"
                width={50}
                height={50}
              />
            )}
          </div>
        </div>
      </nav>

      <div className="container my-5">
        <h1 className="text-center mb-4" style={{ color: '#343a40', fontWeight: 'bold', fontSize: '2.5rem' }}>
          Admin Settings
        </h1>
        <div className="card p-4 shadow-sm">
          <form onSubmit={handleSaveSettings} className="settings-form">
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="pageTitle" className="form-label">Page Title</label>
                <input
                  type="text"
                  id="pageTitle"
                  name="pageTitle"
                  value={settings.pageTitle}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="pageSubtitle" className="form-label">Page Subtitle</label>
                <input
                  type="text"
                  id="pageSubtitle"
                  name="pageSubtitle"
                  value={settings.pageSubtitle}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="backgroundColor" className="form-label">Background Color</label>
                <input
                  type="color"
                  id="backgroundColor"
                  name="backgroundColor"
                  value={settings.backgroundColor}
                  onChange={handleInputChange}
                  className="form-control form-control-color"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="buttonColor" className="form-label">Button Color</label>
                <input
                  type="color"
                  id="buttonColor"
                  name="buttonColor"
                  value={settings.buttonColor}
                  onChange={handleInputChange}
                  className="form-control form-control-color"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="heroSectionColor" className="form-label">Hero Section Color</label>
                <input
                  type="color"
                  id="heroSectionColor"
                  name="heroSectionColor"
                  value={settings.heroSectionColor}
                  onChange={handleInputChange}
                  className="form-control form-control-color"
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="buttonText" className="form-label">Button Text</label>
              <input
                type="text"
                id="buttonText"
                name="buttonText"
                value={settings.buttonText}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="logoUrl" className="form-label">Logo URL</label>
              <input
                type="text"
                id="logoUrl"
                name="logoUrl"
                value={settings.logoUrl}
                onChange={handleInputChange}
                className="form-control"
                disabled
              />
            </div>
            <div className="mb-3 text-center">
              <label htmlFor="logoUpload" className="form-label">Upload Logo</label>
              {logoPreview && (
                <div className="logo-preview mb-3 text-center mx-auto">
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    className="img-thumbnail"
                    style={{ maxHeight: '150px' }}
                  />
                </div>
              )}
              <input
                type="file"
                id="logoUpload"
                name="logoUpload"
                onChange={handleLogoFileChange}
                className="form-control"
              />
              <button
                type="button"
                onClick={handleUploadLogo}
                className="btn btn-secondary mt-3 mx-auto d-block"
              >
                Upload Logo
              </button>
            </div>
            <button
              type="submit"
              className="btn mt-4 w-100"
              style={{ backgroundColor: settings.buttonColor, color: 'white' }}
            >
              Save Settings
            </button>
            <a
              href="/admin"
              className="btn btn-link mt-3 w-100"
              style={{ textDecoration: 'none', color: '#007bff', fontWeight: 'bold' }}
            >
              Go to Admin Dashboard
            </a>
          </form>
          {message && (
            <div className="alert alert-info mt-4 text-center" role="alert">
              {message}
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .admin-settings-container {
          min-height: 100vh;
        }
        .profile-picture {
          border-radius: 50%;
          object-fit: cover;
        }
        .logout-button {
          height: 40px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .logo-preview {
          display: block;
          margin: 0 auto;
        }
      `}</style>
    </div>
  );
}
